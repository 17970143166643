import React from "react";
import "../App.css";

const experiences = [
  {
    logo: require("../images/Tesla.jpg"),
    company: "Tesla",
    role: "Software Engineer Intern",
    techStack: "Spring, Flask, Kafka, MongoDB, MySQL, gRPC",
    date: "Sept 2024 - Dec 2024",
    bullets: [
      "Created a REST service in Spring and MongoDB to allow Tesla to blacklist fraudulent users along with a CRON-based temporary safelist feature in production.",
      "Developed a high-performance validation API in Spring to verify all of Tesla’s live transactions for fraudulent users with an average response time of 500 ms.",
      "Integrating gRPC into the service to optimize live transaction validation to reduce API response times by several magnitudes below 500 ms and enhance scalability.",
      "Built a Microservice in Flask to migrate 20,000,000+ transaction records from an internal vertical database to a MySQL infrastructure utilizing Kafka Broker to allow for real-time analytics."
    ],
  },
  {
    logo: require("../images/Activision.jpg"),
    company: "Activision",
    role: "Software Engineer Intern",
    techStack: "Docker, Kubernetes, Vitess, AWS Redis, MySQL",
    date: "May 2024 - Aug 2024",
    bullets: [
      "Implemented data partitioning strategies using Vitess to enable horizontal scaling of MySQL databases, resulting in a 25% increase in the speed of player detail retrieval for Call of Duty Black Ops 6.",
      "Developed a caching layer using AWS Redis to store session data, decreasing retrieval times by 90%, significantly improving responsiveness in multiplayer sessions."
    ],
  },
  {
    logo: require("../images/GSoC.jpg"),
    company: "Google Summer of Code 2024",
    role: "Open Source Developer",
    techStack: "FastAPI, Flutter, Docker",
    date: "May 2024 - Aug 2024",
    bullets: [
      "Developed a Dockerized REST API in FastAPI to support dynamic voice-to-voice interaction for 10+ mobile apps, enabling flexible model selection for speech recognition and synthesis.",
      "Created a mobile app in Flutter to integrate all voice models to demonstrate the flexibility of the models."
    ],
  },
  {
    logo: require("../images/Cisco.jpg"),
    company: "Cisco",
    role: "Software Engineer Intern",
    techStack: "React, Ruby on Rails, C++, PostgreSQL",
    date: "May 2023 - Dec 2023",
    bullets: [
      "Developed an internal dashboard used by 1000+ engineers using React and Ruby on Rails, displaying virus scan data sourced from a PostgreSQL database.",
      "Contributed to eight version updates of the Mac & Linux virus scanner software, updating 25+ features in C++ and Ruby, including scan optimizations."
    ],
  },
  {
    logo: require("../images/SAP.jpg"),
    company: "SAP",
    role: "Software Developer Intern",
    techStack: "Golang, Python, Docker, Kubernetes",
    date: "Sept 2022 - Apr 2023",
    bullets: [
      "Configured Prometheus alerts to identify system errors for 200+ Kubernetes Pods, notifying Slack channels for 10,000+ engineers.",
      "Deployed Docker containers containing malicious code in Golang and Python to manually trigger the faulty scenarios to ensure alerts fire correctly."
    ],
  },
  {
    logo: require("../images/UBC.jpg"),
    company: "University of British Columbia",
    role: "Frontend Developer",
    techStack: "React",
    date: "May 2022 - Aug 2022",
    bullets: [
      "Deployed the UBC Integrated Engineering website with over 2000+ monthly visitors after creating the student projects and events pages in React.",
      "Created the 2nd version of the design library, updating 15+ UI components using SASS to improve mobile scalability across 50+ UBC Engineering websites.",
      "Relaunched the UBC Equip Health website after implementing new UI card formats for 100+ subpages and resolving 55+ visual bugs in CSS for cross-device compatibility."
    ],
  }
];

const Work = () => (
    <section className="section">
      <h2>Experience</h2>
      {experiences.map((experience, index) => (
        <div className="experience-item" key={index}>
          <img
            src={experience.logo}
            alt={`${experience.company} Logo`}
            className="company-logo"
          />
          <div className="experience-content">
            <h3>{experience.role} @ {experience.company}</h3>
            <p className="date">{experience.date}</p>
            <p>
              <strong>Tech Stack:</strong> {experience.techStack}
            </p>
            <ul>
              {experience.bullets.map((bullet, i) => (
                <li key={i}>{bullet}</li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </section>
  );
  

export default Work;
