import React from "react";
import "../App.css";

const Header = () => (
  <header className="header">
    <h1>Ryan Kim</h1>
    <p>
      Hi! I’m a tech enthusiast studying computer science to
      solve real-world problems. Check out my journey below!
    </p>
  </header>
);

export default Header;
