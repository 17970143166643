import React from "react";
import "../App.css";

const extracurriculars = [
  {
    logo: require("../images/CSSS.jpg"),
    title: "President",
    club: "UBC Computer Science Student Society",
    date: "May 2023 - Ongoing",
    bullets: [
      "Leading a team of 70+ executives to oversee operations for 25+ yearly events for 2800+ CS students.",
      "Helped organize the UBC Technical Career Fair with 30+ companies and 2000+ attendees across 10+ universities.",
      "Organized the year-end Gala with over 150 attendees, assisting with contacting venues and external event staff.",
    ],
  },
  {
    logo: require("../images/SUS.jpg"),
    title: "Webmaster",
    club: "UBC Science Undergraduate Society",
    date: "Jul 2023 - Apr 2024",
    bullets: [
      "Created the student discount website to support 15 local businesses for 9700+ Science students using React and Google Firebase datbase and authentication.",
    ],
  },
  {
    logo: require("../images/Launchpad.jpg"),
    title: "Software Developer",
    club: "UBC Launch Pad",
    date: "Jul 2023 - Apr 2024",
    bullets: [
      "Created a Google Chrome extension to scrape and summarize news articles, implementing a keyword finder that allows users to quickly see definitions of keywords in the articles.",
    ],
  },
  {
    logo: require("../images/TEALS.jpg"),
    title: "Computer Science Instructor",
    club: "Microsoft TEALS Program",
    date: "Jun 2022 - Jun 2024",
    bullets: [
      "Assisted teachers at North Delta Secondary School with AP Computer Science Principles, to ensure every highschool student in North America has an opportunity to start coding early.",
      "Created and graded coding exercies in Python to teach basic coding concepts such as classes, loops, functions, and more."
    ],
  },
  {
    logo: require("../images/GDSC.jpg"),
    title: "Events & Logistics Coordinator",
    club: "UBC Google Development Student Club",
    date: "Sep 2022 - Apr 2023",
    bullets: [
      "Led a panel event of senior students to help younger students with their journey in tech with 60+ attendees.",
    ],
  },
  {
    logo: require("../images/Gamedev.jpg"),
    title: "Game Developer",
    club: "UBC Game Development Club",
    date: "Sep 2020 - Apr 2021",
    bullets: [
      "Created a medical simulator desktop game in a team of 10 using Unity and C#, voted Best Game of the Year 2020-2021 by industry judges.",
    ],
  },
];

const Extracurriculars = () => (
  <section className="section">
    <h2>Extracurriculars</h2>
    {extracurriculars.map((activity, index) => (
      <div className="extracurricular-item card" key={index}>
        <img
          src={activity.logo}
          alt={`${activity.title} Logo`}
          className="company-logo"
        />
        <div className="extracurricular-content">
          <h3>
            {activity.title} @ {activity.club}
          </h3>
          <p className="date">{activity.date}</p>
          <ul>
            {activity.bullets.map((bullet, i) => (
              <li key={i}>{bullet}</li>
            ))}
          </ul>
        </div>
      </div>
    ))}
  </section>
);

export default Extracurriculars;
